<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增点检保养计划"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      :width="680"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="编号">
              <a-input
                v-decorator="[
                  'number',
                  {
                    rules: [
                      { required: true, message: '请输入编号' },
                      { max: 32, message: '超出最大长度(32)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: '请输入名称' },
                      { max: 64, message: '超出最大长度(64)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="频率">
              <a-input-number
                v-decorator="['frequency', { rules: [{ required: true, message: '请选择类型' }] }]"
                :precision="0"
                :min="0"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="频率单位">
              <a-select
                v-decorator="[
                  'frequency_unit',
                  {
                    rules: [{ required: true, message: '请选择频率单位' }],
                  },
                ]"
                :allowClear="true"
                style="width: 100%"
              >
                <a-select-option value="hour">小时</a-select-option>
                <a-select-option value="day">天</a-select-option>
                <a-select-option value="week">周</a-select-option>
                <a-select-option value="month">月</a-select-option>
                <a-select-option value="quarter">季度</a-select-option>
                <a-select-option value="year">年</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开始时间">
              <a-date-picker v-decorator="['start_date']" valueFormat="YYYY-MM-DD" :allowClear="true" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="结束日期">
              <a-date-picker v-decorator="['end_date']" valueFormat="YYYY-MM-DD" :allowClear="true" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="类型">
              <a-select
                v-decorator="[
                  'type',
                  {
                    initialValue: 'inspection',
                    rules: [{ required: true, message: '请选择类型' }],
                  },
                ]"
                :allowClear="true"
                style="width: 100%"
                @change="onChangeType"
              >
                <a-select-option value="inspection">设备点检</a-select-option>
                <a-select-option value="maintenance">设备保养</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-input v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-tabs>
        <a-tab-pane key="equipment" tab="设备清单" forceRender>
          <EquipmentPane v-model="equipmentItems" />
        </a-tab-pane>
        <a-tab-pane v-if="planType === 'inspection'" key="inspection" tab="点检项目" forceRender>
          <InspectionPane v-model="inspectionItems" />
        </a-tab-pane>
        <a-tab-pane v-if="planType === 'maintenance'" key="maintenance" tab="保养项目" forceRender>
          <MaintenancePane v-model="maintenanceItems" />
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import { maintenancePlanCreate, getMaintenancePlanNumber } from "@/api/equipment";

export default {
  components: {
    EquipmentPane: () => import("./EquipmentPane"),
    InspectionPane: () => import("./InspectionPane"),
    MaintenancePane: () => import("./MaintenancePane"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
      planType: "inspection",

      equipmentItems: [],
      inspectionItems: [],
      maintenanceItems: [],
    };
  },
  methods: {
    onChangeType(value) {
      this.planType = value;
    },
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          if (this.equipmentItems.length === 0) {
            this.$message.warning("请添加设备台账");
            return;
          }
          if (values.type === "inspection" && this.inspectionItems.length === 0) {
            this.$message.warning("请添加点检项目");
            return;
          }
          if (values.type === "maintenance" && this.maintenanceItems.length === 0) {
            this.$message.warning("请保养项目");
            return;
          }

          const formItem = { ...values };
          formItem["equipment_set"] = this.equipmentItems.map((item) => item.id);

          if (values.type === "inspection") {
            formItem["maintenance_item_set"] = this.inspectionItems.map((item) => item.id);
          }
          if (values.type === "maintenance") {
            formItem["maintenance_item_set"] = this.maintenanceItems.map((item) => item.id);
          }

          this.confirmLoading = true;
          maintenancePlanCreate(formItem)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
  watch: {
    visible(status) {
      if (status) {
        getMaintenancePlanNumber().then((data) => {
          this.dataForm.setFieldsValue(data);
        });
      }
    },
  },
};
</script>

<style scoped></style>
